import Navbari from './Navbar.js';
import Header from './Head.js';
import Info from './Info.js';
import Program from './program';
import Fac from './fasilitas';
import Teacher from './teacher';
import Latest from './latest';
import Testimoni from './testimoni';
import Footer from './footer';
import './App.css';

function App() {
  return (
    <>
    
    <Navbari></Navbari>
    <Header></Header>
    <Info></Info>
    <Program></Program>
    <Fac></Fac>
    <Teacher></Teacher>
    <Latest></Latest>
    <Testimoni></Testimoni>
    <Footer></Footer>
    </>
    
  );
}

export default App;
