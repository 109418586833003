import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from './img/logo.png';
const Navbari = () =>{
    return(
        <Navbar bg="light" expand="lg" className="navbar-spec" sticky="top" >
     
        <Navbar.Brand href="#"><img src={Logo} className="logo" /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#program">Programs</Nav.Link>
            <Nav.Link href="https://discord.gg/dKcNyeVwum" target="_blank">Community</Nav.Link>
            <Nav.Link href="https://blog.kelasfeez.id/">Blog</Nav.Link>
          </Nav>
          <div className="button-now"><a href="https://panel.kelasfeez.id" ><button className="btn btn-feez-filled "><i className="fa fa-code"></i> Gabung Kelas <span className="btn-join"></span></button></a></div> &nbsp;&nbsp;&nbsp;&nbsp;
        </Navbar.Collapse>
      
    </Navbar>
    );  
}

export default Navbari;