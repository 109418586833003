
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import star from './img/star.png';
import Accordion from 'react-bootstrap/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
const Testimoni = ()=> {
    return (
<>
<div className = "testimoni">
        <Container>
            <h1>Kata Mereka <br />
            Belajar di Feez itu ...</h1>
            <Carousel variant="light">
            <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
            
        
        <p>Kaka pengajar memiliki pembawaan yang santai dan humble, untuk materinya mulai dari dasar sekali namun sangat bermanfaat dan saya mendapat pengetahuan baru yang tidak saya peroleh ketika belajar sendiri seperti macam macam fungsi file header c++, penggunaan #define, bitwise operation, dan pemanfaatan sub program</p>
        <h5>Yoseph Oktavianus Yusanto</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
          
           
            <p>   Pengajarnya sangat friendly dan gaul, jadi waktu belajarnya menyenangkan dan tidak terlalu bosan^^</p>
            <h5>Lindsay Allyson Wijaya </h5>
        </Carousel.Caption>
      </Carousel.Item>
 
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
          
          <p>
          Sudah sepuh jadi ngajarin nya langsung ngerti
          </p>
          <h5>Fathir Muzaki</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
          
          <p>
          keren bgt, mudah dipahami
          </p>
          <h5>Muhammad Adi Anugerah Arrahman</h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
          
          <p>
          Pengajarnya : Orangnya sangat ramah dan penjelasannya mudah dipahami dan sangat mendetail
          </p>
          <h5>Darren Pratama Julio </h5>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
      <img
          className="d-block w-100"
          src={star}
          alt="feez-testimonials"
        />
        <Carousel.Caption>
          
          <p>
          Penjelasan yang diberikan mudah dipahami karena pengajar bersikap santai jadi tidak tegang, dan tidak merasa takut.
          </p>
          <h5>Aulia Salsabila </h5>
        </Carousel.Caption>
      </Carousel.Item>


      
    </Carousel>

    <br />
    <br />
   
    <h1 align="center">F.A.Q</h1><br />
    
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Saya baru mulai belajar coding dari 0 apakah bisa?</Accordion.Header>
        <Accordion.Body>
          Bisa banget , kurikulum belajar telah kami desain sedemikian rupa menerapkan konsep pembelajaran yang bahkan bisa difahami oleh anak - anak usia 9 tahun sekalipun
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Berapa lama jam belajarnya?</Accordion.Header>
        <Accordion.Body>
         Pembelajaran dilaksanakan di hari Sabtu - Minggu selama 2 jam/pertemuan dengan total pertemuan 8x pertemuan
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Materi apa aja yang akan dibahas di kelas? </Accordion.Header>
        <Accordion.Body>
         Materi - materi fundamental pemrograman akan dibahas. Silabus Kelas Coding Feez mengacu kepada Silabus resmi <i>International Olympiad in Informatics (IOI)</i>
        </Accordion.Body>
      </Accordion.Item>
    
    </Accordion> 
    <br/>
    <p align="center">Punya pertanyaan? hubungi kami via whatsapp </p>
    <div align="center"><a href="https://wa.link/pkmpvi"><button class="btn btn-feez-filled"> 
    💬 Hubungi Kami</button></a></div>
        </Container>
        </div>
</>
    );
}

export default Testimoni;