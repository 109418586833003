import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import albert from './img/albert.png';
import daniel from './img/daniel.png';
const Teacher = ()=>{
    return (
        <>
        <div className="teacher">
            <Container>
                <h1 align="center">Tentor Terbaik</h1>
                <h1 align="center" className="text-yell">Berprestasi Internasional</h1>
                <br /><br />
                <Row>
                <Col xs={2}>

                </Col>
                    <Col xs={2} align="center">
                    <img src ={albert} className="img-teacher"/>
                    
                    </Col>
                    <Col xs={6} >
                    <h3>Coach Albert</h3>
                    <p>
                        <li>Silver Medalist International Collegiate Programming Contest (ICPC) Asia Jakarta Regional Contest</li>
                        <li>Silver Medalist International Olympiad in Informatics (IOI) 2022</li>
                        <li>1st Winner Kompetisi Sains Nasional (KSN) 2021 Bidang Informatika </li>
                        </p>
                    </Col>
                    <Col xs={2}>

                    </Col>
                </Row>
                <br />
                <Row>
                <Col xs={2}>

</Col>
                    <Col xs={2} align="center">
                    <img src ={daniel} className="img-teacher"/>
                    
                    </Col>
                    <Col xs={6}>
                    <h3>Coach Daniel</h3>
                    <p>
                        <li>University of Toronto - Statistics</li>
                        <li>Beasiswa Indonesia Maju 1 Awardee</li>
                        <li>Finalist National Science Competition in Informatics</li>
                        </p>
                    </Col>
                    <Col xs={2}>

</Col>
                </Row>
            </Container>
            </div>
            </>
    );
}

export default Teacher;