import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import vid1 from './img/vid.mp4';
import Button from 'react-bootstrap/Button';
import screen from './img/screen-2.png';
import Accordion from 'react-bootstrap/Accordion';

const Fac = ()=> {
    return(
        <>
        <div className="fasilitas">
        <h1>Apa Yang Kamu Dapat <br/> Jika Bergabung Di Kelas Coding Feez?</h1>
        <br/><br/><br/>
        <Container>
            <Row align="center">
                <Col xs={4} align="right">
                    <h1 > 📚 <span className="text-grad text-pop text-600 text-title-program shine">Kelas Daring <br/>2 x 4 Jam / <br/>Minggu</span></h1>
                </Col>
                <Col xs={4} align="left">
                    <p>
                    Belajar Bareng tentor keren dan berkualitas bahas teori pemrograman dan praktik langsung bikin program secara interaktif 
                    </p>
                </Col>
                <Col xs={4}></Col>
            </Row>
            <Row align="center">
            <Col xs={4}></Col>
            <Col xs={4} align="left" className="mobile-display">
                    <h1 >⏺️<span className="text-grad text-pop text-600 text-title-program">  E Modul dan
Record Video</span></h1>
                </Col>
                <Col xs={4} align="right" >
                    <p>
                    Akses rekaman video kelas
kapapun dan di manapun serta 
modul - modul yang bisa kamu baca ulang

                    </p>
                </Col>

                <Col xs={4} align="left" className="desktop-display">
                    <h1> ⏺️ <span  className="text-grad text-pop text-600 text-title-program"> E Modul dan
Record Video</span></h1>
                </Col>
            </Row>
            <Row align="center">
                <Col xs={4} align="right">
                    <h1 >💬 <span className="text-grad text-pop text-600 text-title-program">Komunitas <br/>Diskusi</span></h1>
                </Col>
                <Col xs={4} align="left">
                    <p>
                    Forum buat diskusi bersama siswa lainnya dan jaringan komunikasi yang bisa kamu manfaatin buat nambah relasi 
                    </p>
                </Col>
                <Col xs={4}></Col>
            </Row>
           
        </Container>
        </div>
        </>

    );
}

export default Fac;