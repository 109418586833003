
const Footer = () =>{
    return(
        <>
        <br/>  <br/>  <br/>
    <footer className="site-footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6">
            <h6>Tentang Kami</h6>
            <p className="text-justify">Feez adalah platform edukasi dan pengembangan skill
bagi kalangan pelajar Indonesia di bidang teknologi dan inovasi digital. 
</p>
          </div>

       

          <div className="col-xs-6 col-md-3">
            <h6>Bantuan dan Panduan</h6>
            <ul className="footer-links">
              <li><a href="#">Syarat dan Ketentuan</a></li>
              <li><a href="#">Kebijakan Privasi</a></li>
              <li><a href="#">Disclaimer</a></li>
              <li><a href="#">Hubungi Kami</a></li>
             
            </ul>
          </div>
          <div className="col-xs-6 col-md-3">
            <h6>CONTACT US</h6>
            <ul className="footer-links">
              <li><i class="fa fa-phone"></i> +62 8956010 77613</li>
              <li><i class="fa fa-envelope"></i>feezedutech@gmail.com</li>
              <li><i class="fa fa-instagram"></i> feezedutech</li>
             
             
            </ul>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8 col-sm-6 col-xs-12">
            <p className="copyright-text">Copyright &copy; 2022 All Rights Reserved by <b>Generasi Digital Kreatif Indonesia</b>
            </p>
          </div>

          <div className="col-md-4 col-sm-6 col-xs-12">
            <ul className="social-icons">
              <li><a className="dribbble" href="https://instagram.com/feezedutech"><i className="fa fa-instagram"></i></a></li>
              <li><a className="twitter" href="https://wa.link/pkmpvi"><i className="fa fa-whatsapp"></i></a></li>
           
            
            </ul>
          </div>
        </div>
      </div>
</footer>
</>
);
}

export default Footer;