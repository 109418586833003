import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const Program = ()=>{
    return(
            <>
            <div className="program" id="program">
            <Container>
                <h1>OUR PROGRAMS</h1>
                <Row>
                    <Col xs={6}>
                        <div className="card-yell">
                            <div className="card-yell-text">
                            <h1 >
                            🖱️ <span className="text-grad text-pop text-600 text-title-program"> Weekend <br/>Coding Class</span>
                             
                            </h1>
                            <p><b><i>Kelas Coding Gratis</i></b> untuk kalangan pelajar usia 9 - 19 Tahun</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="card-yell">
                            <div className="card-yell-text">
                            <h1 >
                            🐱‍💻 <span className="text-grad text-pop text-600 text-title-program">Codejourney <br/>Program</span>
                             
                            </h1>
                            <p>Program pembekalan skill pemrograman untuk pelajar</p>
                            </div>
                        </div>
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col xs={6}>
                        <div className="card-yell">
                            <div className="card-yell-text">
                           <h1>
                           🥇 <span className="text-grad text-pop text-600 text-title-program"> OSN Intensive <br/>Special Class</span>
                             
                            </h1>
                            <p>Kelas spesial untuk persiapan Olimpiade Sains Nasional Informatika</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6}>
                        <div className="card-yell">
                            <div className="card-yell-text">
                            <h1> 
                                <img src="https://assets-global.website-files.com/6257adef93867e50d84d30e2/636e0a69f118df70ad7828d4_icon_clyde_blurple_RGB.svg" className="img-discord"/>
                            <span className="text-grad text-pop text-600 text-title-program"> Discord <br/>Community</span>
                             
                            </h1>
                            <p>Forum komunitas diskusi bersama dan sharing berbagai wawasan baru</p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            </div>
            </>
    );
}

export default Program;