import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bef from './img/bef.png';
const Latest = ()=>{
    return (
        <>
        <div className="label-program"><i>KELAS TERBARU</i></div>
        <div className="latest">
        <br />
                            <br />
                            <br />
        <div className="container">
                        <div className="row">
                        
                                <div className="col-md-5">
                                    <h1 className="title-latest">CODEJOURNEY</h1>
                                    <h1 className="text-yellg title-latest">BOOTCAMP</h1>
                                    <div className="mobile-display">
                                            <div className="card-glass">
                                                <div className="del"><img src={bef} class="bef" /></div>
                                                <h1>Rp99.000 <span className="text-ijo">(51% off)</span> </h1>
                                                <ul className="list-check">
                                                        <li>Kelas Daring bersama Tentor</li>
                                                        <li>Akses Modul dan Rekaman Video</li>
                                                        <li>Assignment dan Post Test</li>
                                                        <li>Grup Komunitas sesama peserta</li>
                                                        <li>E Sertifikat dan Evaluasi</li>

                                                </ul>

                                            </div>
                                </div>
                                    <p className="text-white"><span className="text-yell text-700">Belajar fundamental Pemrograman C++
<br />8x pertemuan @ 2 jam</span> , kamu akan mempelajari fundamental pemrograman mulai dari Matematika dalam Pemrograman sampai ke Implementasinya, sintaks dan semantikal bahasa pemrograman C++ , dan dasar - dasar algoritma</p>
                                    <a href="https://panel.kelasfeez.id" target="_blank"><button className="btn btn-feez-filled btn-hove">Gabung Sekarang</button></a>
                                    </div>
                                <div className="col-md-7 desktop-display">
                                            <div className="card-glass">
                                                <div className="del"><img src={bef} class="bef" /></div>
                                                <h1>Rp99.000 <span className="text-ijo">(51% off)</span> </h1>
                                                <ul className="list-check">
                                                        <li>Kelas Daring bersama Tentor</li>
                                                        <li>Akses Modul dan Rekaman Video</li>
                                                        <li>Assignment dan Post Test</li>
                                                        <li>Grup Komunitas sesama peserta</li>
                                                        <li>E Sertifikat dan Evaluasi</li>

                                                </ul>

                                            </div>
                                </div>
                        </div>
                </div>

            </div>
        </>
    );
}

export default Latest;
