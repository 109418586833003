import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import screen from './img/screen-2.png';
const Header = ()=>{
    return(
        <>
        <div className="header">
        <Container>
                <Row>
                <Col className="mobile-display">
                    <img src={screen} className="screen-img"/>
                    <div className="screen"></div>
                    </Col>
                    <Col>
                      <h1 className="text-yell">Belajar Coding Interaktif Bersama Tentor Berprestasi Internasional </h1>
                     <div className="divider"></div>
                      <p>Kursus Akselerasi skill digital dengan model pembelajaran modern dan kurikulum efektif yang terstruktur, <i>biaya mulai dari Rp0</i></p>
                      <br/>
                      <a href="https://panel.kelasfeez.id" ><Button variant="outline-warning" className="btn-feez">Gabung Sekarang</Button></a>
                    
                    </Col>
                    <Col className="desktop-display">
                    <img src={screen} className="screen-img"/>
                    <div className="screen"></div>
                    </Col>
                </Row>
        </Container>
        </div>
        </>
    );
}

export default Header;