
import React, { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import vid1 from './img/vid.mp4';
import Button from 'react-bootstrap/Button';
import screen from './img/screen-2.png';
import Accordion from 'react-bootstrap/Accordion';



const Info = ()=>{   
    const [open, setOpen] = useState(false);
  

    
   
  
    return(
    <>
        <div className="info">
            <Container>
                <Row>
                    
                <Col xs={12}>
                <br/>
                <h2 align="center"><span className="text-yell">Feez</span> Menyediakan <span className="text-yell">Learning-Environment</span> yang Sangat Sesuai untuk kamu Si Pemula ataupun Lanjutan</h2>
                
                </Col>
                <Col xs={12}>
                <br/>
                <p align="center">Kami telah berhasil menciptakan transformasi  model pembelajaran kursus skill digital 
            di mana <span class="text-yell">puluhan siswa</span> dari kalangan pemula maupun mahir 
            telah memastikan skill mereka meningkat setelah bergabung kelas</p>
                
                </Col>
                </Row>
                <Row>
                    <Col xs={6}>
                        <br />
                    <video className="video" controls>
                <source src={vid1} type="video/mp4"></source>
                    </video>
                    </Col>
                    <Col xs={6}>
                    <br />
                    <h5>KENAPA MEMILIH KAMI?</h5><br />
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Kurikulum Belajar yang Modern & Inovatif</Accordion.Header>
        <Accordion.Body>
          <b>Neuronik</b> , metode belajar sesuai kinerja dua bagian otak (kanan dan kiri)
          <br/>
          <b>Interaktif</b> , belajar asyik dan menyenangkan
          <br/>
          <b>Pola Belajar  </b> , Afektif - Kognitif - Psikomotorik , melibatkan kecakapan/literasi siswa dalam memahami konsep dan mampu menerapkannya dalam kasus kontekstual
          <br/><b><i>Satisfaction</i></b>, menciptakan suasana belajar yang baru dan berkesan menarik
          <br/><b>Kompetitif</b>, meningkatkan daya saing dan kualitas keterampilan
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Pengajar yang Ahli dan Berkualitas</Accordion.Header>
        <Accordion.Body>
         <b>Pengajar Prestisius</b> akan memberikan kesan pengalaman belajar yang sangat baik dan metode ajar yang berbeda
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Materi Belajar Terstruktur</Accordion.Header>
        <Accordion.Body>
         <b>Ibarat menanam</b> kita mulai bahkan dari benih hingga tunas baru ke akar, materi disajikan secara runut dan memiliki konsep yang disesuaikan dengan pemahaman siswa
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Fasilitas Super Lengkap</Accordion.Header>
        <Accordion.Body>
         <b>Mulai dari rekaman kelas on Demand, diskusi interaktif bareng tentor, modul materi, evaluasi dan rapor bisa kamu dapetin semuanya </b>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <br/>
            <br/>
            <br/>
                    </Col>
                </Row>
           
            </Container>
        </div>
        
        </>
    );
}

export default Info;